@media only screen and (max-width: 600px) {
  .home {
    border-radius: 0;
  }
}
.booking-page {
  background-color: black;
  /* background-image: url("../asset/bg.jpg"); */
  height: 100%;
  min-height: 90vh;

  .calender-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 80vh;
    overflow: scroll;
    .calender-item {
      /* padding: 1rem; */
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      max-width: 440px;
      width: 100%;
      /* border: 0.1px solid #FFFFFF; */
      border-radius: 8px;
      @media only screen and (max-width: 600px) {
        border: 0px;
      }

      /* .footer-page {
        position: absolute;
        bottom: 20px;
      } */
    }
  }

  .category-wrapper {
    display: flex;
    justify-content: center;
    overflow: scroll;
    width: 100%;
    .category-item {
      width: 100%;
      max-width: 960px;
      height: 100%;
      padding: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-radius: 8px;
      /* border: 0.1px solid #ffffff; */
      /* background: black; */
      .category-card {
        width: 49%;
      }
      .category-header {
        width: 100%;
        border: 1px solid #ffffff;
        border-radius: 8px;
        background: black;
      }
    }
    @media only screen and (max-width: 960px) {
      /* .category-item {
       
      } */
    }
    @media only screen and (max-width: 600px) {
      .category-item {
        justify-content: center;
        background: black;
        gap: 30px;
        .category-header {
          width: 100%;
        }
        .category-card {
          width: 100%;
          /* margin-top: 20px; */
        }
      }
    }
  }
  .rate-wrapper {
    display: flex;
    justify-content: center;
    overflow: scroll;
    width: 100%;

    .rate-item {
      width: 100%;
      max-width: 960px;
      height: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      background: black;
      .otarate-container {
        display: flex;
        justify-content: space-around;
        padding: 16px;
        gap: 1rem;
        .ota-stamp {
          width: 15%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .otalogo-container {
          width: 100%;
          max-width: 250px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      @media only screen and (max-width: 600px) {
        .otarate-container {
          .ota-stamp {
            width: 30%;
          }
        }
      }
      .rate-item-card {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 8px;
        border: 0px;
        .rate-card-header {
          display: flex;
          background: black;
          border: 1px solid #ffffff;
          padding: 8px;
          border-radius: 8px;
          .rate-media-container {
            width: 50%;
            .cardmedia {
              width: 416px;
              height: 234px;
              border-radius: 8px;
            }
          }
          .rate-availability {
            display: flex;
            flex-direction: row;
            gap: 16px;
          }
        }
        @media only screen and (max-width: 960px) {
          .rate-card-header {
            flex-direction: column;
            .rate-media-container {
              width: 100%;
              .cardmedia {
                width: 100%;
                object-fit: cover;
              }
            }

            .rate-availability {
              display: flex;
              flex-direction: column;
              gap: 0px;
            }
          }
        }
      }
      .enhance-and-occupancy-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 2rem;
        .enhance-items {
          width: 49%;
          .rates-collection {
            border: 1px solid #ffffff;
            margin-top: 16px;
            border-radius: 8px;
            padding: 8px;
          }
        }
        .occupancy-container {
          width: 49%;
        }
      }
      @media only screen and (max-width: 600px) {
        .enhance-and-occupancy-container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 60px;
          /* margin-top: 2rem; */
          .enhance-items {
            width: 100%;
          }
          .occupancy-container {
            margin-top: 2rem;
            width: 100%;
          }
        }
      }
    }
    /* @media only screen and (max-width: 600px) {
      .rate-item {
        padding: 1rem 0rem 0rem 0rem;
      }
    } */
  }
  @media only screen and (max-width: 960px) {
    .rate-wrapper {
      background: black;
    }
  }

  .summary-wrapper {
    display: flex;
    justify-content: center;
    overflow: scroll;
    width: 100%;
    .summary-item {
      width: 100%;
      max-width: 960px;
      height: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      /* border: 0.1px solid #FFFFFF; */
      border-radius: 8px;
      background: black;
      .summary-item-card {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 8px;
        border: 0px;
        margin-bottom: 24px;
        .summary-card-header {
          display: flex;
          background: black;
          border: 0.5px solid #ffffff;
          padding: 8px;
          border-radius: 8px;
          .summary-media-container {
            width: 416px;

            .cardmedia {
              width: 416px;
              height: 234px;
              border-radius: 8px;
              object-fit: cover;
            }
          }
          .summary-adult-child {
            display: flex;
            flex-direction: row;
            gap: 16px;
          }
        }
        @media only screen and (max-width: 960px) {
          .summary-card-header {
            flex-direction: column;
            .summary-media-container {
              width: 100%;
              .cardmedia {
                width: 100%;
                /* height: calc(100% * 3 / 2) !important; */
                object-fit: cover;
              }
            }
            /* .summary-adult-child {
              display: flex;
              flex-direction: column;
              gap: 0px;
            } */
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      .summary-item {
        border: 0px;
      }
    }
  }
  .details-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    width: 100%;
    flex-direction: column;
    .details-item {
      width: 100%;
      max-width: 960px;
      height: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      border: 0.1px solid #ffffff;
      border-radius: 8px;
      background: black;
      .basic-detail {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .basicItem {
          margin-top: 2rem;
          width: 49%;
        }
      }
      @media only screen and (max-width: 600px) {
        .basic-detail {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;

          .basicItem {
            margin-top: 2rem;
            width: 100%;
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      .details-item {
        border: 0px;
      }
    }
  }

  .mobile-header {
    display: none;
    padding: 1rem;
    // position: sticky;
    // top: 10vh;
    // z-index: 100;
    background: black;
    margin-bottom: 1rem;
  }
  .desktop-header {
    display: block;
    padding: 1rem;
    // position: sticky;
    // top: 10vh;
    // z-index: 100;
    background: black;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 960px) {
    .mobile-header {
      display: block;
      margin-bottom: 0rem;
    }
    .desktop-header {
      display: none;
    }
  }
  @media only screen and (max-width: 600px) {
    .mobile-header {
      margin-bottom: 0rem;
    }
  }
}

.footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
}
@media only screen and (max-height: 550px) {
  .footer-container {
    position: static;
  }
}

.footer-container-detailpage {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
}
@media only screen and (max-width: 600px) {
  .footer-container-detailpage {
    position: static;
  }
}
@media only screen and (max-height: 880px) {
  .footer-container-detailpage {
    position: static;
  }
}

.footer-page {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
