/* Add this to your CSS file */
.cardmedia {
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  z-index: 2;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  list-style: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.slick-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slick-dot.slick-active {
  background-color: #fff;
}
