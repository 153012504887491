.notfount-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 960px) {
  .notfount-container {
    margin-top: 100px;
    position: static;
    top: auto;
    left: auto;
    transform: none;
  }
}
