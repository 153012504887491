.header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #161616;
  // height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 1.6rem 1rem 1.6rem 1rem;
  /* border-radius: 8px 8px 0px 0px; */
  .desktop-info {
    display: block;
  }
  .mobile-info {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    border-radius: 0;
    .desktop-info {
      display: none;
    }
    .mobile-info {
      display: block;
    }
  }
}
